import Vue from 'vue';
import VueRouter from 'vue-router';

const auth = () => import("../middleware/auth");
const log = () => import("../middleware/log");

const AboutView = () => import("../views/AboutView.vue");
const BlogContent = () => import("../views/BlogContent.vue");
const BlogCreate = () => import("../views/BlogCreate.vue");
const BlogEdit = () => import("../views/BlogEdit.vue");
const BlogView = () => import("../views/BlogView.vue");
const BlogFour = () => import("../views/BlogViewFour.vue");
const BlogOne = () => import("../views/BlogViewOne.vue");
const BlogThree = () => import("../views/BlogViewThree.vue");
const BlogTwo = () => import("../views/BlogViewTwo.vue");
const BlogFive = () => import("../views/BlogviewFive.vue");
const HomeView = () => import("../views/HomeView.vue");
const LoginView = () => import("../views/LoginView.vue");
const ManageBlog = () => import("../views/ManageBlogView.vue");
const OurTeamView = () => import("../views/OurTeamView.vue");
const PortfolioView = () => import("../views/PortfolioView.vue");

Vue.use(VueRouter)

const routes = [
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      middleware: log,
    },
  },
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/ourteam',
    name: 'ourteam',
    component: OurTeamView
  },
  {
    path: '/portfolio',
    name: 'porfolio',
    component: PortfolioView
  },
  {
    path: '/blog',
    name: 'blog',
    component: BlogView
  },
  {
    path: '/blogone',
    name: 'blogone',
    component: BlogOne
  },
  {
    path: '/blogtwo',
    name: 'blogtwo',
    component: BlogTwo
  },
  {
    path: '/blogthree',
    name: 'blogthree',
    component: BlogThree
  },
  {
    path: '/blogfour',
    name: 'blogfour',
    component: BlogFour
  },
  {
    path: '/blogfive',
    name: 'blogfive',
    component: BlogFive
  },
  {
    path: '/:id',
    name: 'blogcontent',
    component: BlogContent
  },
  {
    path: '/manage/list',
    name: 'manage',
    component: ManageBlog,
    meta: {
      middleware: [auth, log],
    },
  },
  {
    path: "/manage/create",
    name: "blog-create",
    component: BlogCreate,
    meta: {
      middleware: [auth, log],
    },
  },
  {
    path: "/manage/edit/:id",
    name: "blog-edit",
    component: BlogEdit,
    meta: {
      middleware: [auth, log],
    },
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
