<template>
  <div id="app">
    <router-view v-if="!isLoading" />
  </div>
</template>

<script>
import { onAuthStateChanged, signInAnonymously } from "firebase/auth";
import { auth } from './script/firebase';

export default {
    data: () => {
        return {
          isLoading: true,
        }
    },
    beforeMount() {
      const self = this
      onAuthStateChanged(auth, async (user) => {
        if (!user) {
          await signInAnonymously(auth)
        }
        self.isLoading = false
      })
    },
}
</script>
