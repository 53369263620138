import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
   initializeFirestore, memoryLocalCache
} from "firebase/firestore";

// https://firebase.google.com/docs/web/setup#available-libraries
const firebaseConfig = {
   apiKey: "AIzaSyCCwQxD7DlxZtmxP1bhVu1GMz8ONZdXLLo",
   authDomain: "stoked-monitor-243503.firebaseapp.com",
   projectId: "stoked-monitor-243503",
   storageBucket: "stoked-monitor-243503.appspot.com",
   messagingSenderId: "228978310254",
   appId: "1:228978310254:web:7c41015478762140b3fa7f",
   measurementId: "G-Y64LQ1NQK4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = initializeFirestore(app, {localCache: memoryLocalCache()})

export { app, auth, db };
