import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueI18n from 'vue-i18n'
import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// import '../public/js/main.js'
// import '../public/js/vendor/modernizr-3.5.0.min.js';
// import '../public/js/jquery.min.js';
// import '../public/js/bootstrap.bundle.min.js';
// import '../public/js/plugin.min.js';
// import '../public/js/preloader.js';
// import '../public/js/main.js';

Vue.config.productionTip = false

// import css
// require('./assets/css/bootstrap.min.css');
// require('./assets/css/plugin.min.css');
// require('./assets/css/style.css');
// require('./assets/css/custom.min.css');
// require('./assets/css/responsive.css');
// require('./assets/css/darkmode.css');
Vue.use(BootstrapVue)

Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: 'en',
  messages: {
    en: require('../locales/en-US.json'),
    th: require('../locales/th-TH.json')
  }
});

new Vue({
  router,
  render: h => h(App),
  i18n
}).$mount('#app')
